<template>
    <div class="pt-5">
        <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
            <li>
                <a class="text-500 no-underline line-height-3">eSocial</a>
            </li>
            <li class="px-2">
                <i class="pi pi-angle-right text-500 line-height-3"></i>
            </li>
            <li>
                <span class="text-900 line-height-3">{{ this.title }}</span>
            </li>
        </ul>
        <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
            <div>
                <div class="font-medium text-3xl text-900">Eventos</div>
                <div class="flex align-items-center text-700 flex-wrap">
                    <div class="mr-5 flex align-items-center mt-3">
                        <i class="pi pi-globe mr-2"></i>
                        <span>Ambiente de produção restrita</span>
                    </div>
                </div>
            </div>
            <div class="mt-3 lg:mt-0">
                <Button v-if="isCancelable" :disabled="!$checkPermission('esocial_eventos:S3000lote')"
                        class="p-button-outlined w-8rem mr-1 p-button-danger" label="Gerar S-3000"
                        @click="showCancel"></Button>
                <Button v-if="isSendable" :disabled="!$checkPermission('esocial_eventos:enviar')"
                        class="p-button-outlined w-8rem mr-1" label="Enviar" @click="sendEventos"></Button>
                <Button :disabled="!$checkPermission('esocial_eventos:consultar')"
                        class="p-button-outlined w-8rem mr-1" label="Consultar" @click="getEventos"></Button>
                <Button :disabled="!$checkPermission('esocial_eventos:criar')" class="p-button w-8rem" icon="pi pi-plus"
                        label="Novo" @click="$router.push('/esocial/new')"></Button>
            </div>
        </div>
    </div>
    <Divider/>
    <div class="p-fluid grid">
        <div v-if="showTipo" class="field col-1 m-0 pr-1">
            <label for="txtFilterTipoEvento">Tipo</label>
            <MultiSelect id="txtFilterTipoEvento" v-model="filteredEventoTipo" :options="tiposEventos"
                         class="app-multiselect" dataKey="id" display="chip" optionLabel="name"
                         placeholder="Selecione..."/>
        </div>
        <div :class="showTipo ? 'pl-1 col-2' : 'col-3'" class="field m-0 pr-1">
            <label for="txtFilterCliente">Cliente</label>
            <MultiSelectCliente id="txtFilterCliente" v-model="filteredCliente" dataKey="id" optionLabel="name"
                                placeholder="Selecione o cliente..."/>
        </div>
        <div class="field col-2 m-0 pr-1 pl-1">
            <label for="txtFilterFuncionario">Funcionário</label>
            <MultiSelectEsocialFuncionario id="txtFilterFuncionario" v-model="filteredFuncionario"
                                           :customers="filteredCliente"/>
        </div>
        <div class="field col-2 m-0 pr-1 pl-1">
            <label for="txtFilterUnidade">Unidade</label>
            <MultiSelectEsocialUnidade id="txtFilterUnidade" v-model="filteredUnidade" :customers="filteredCliente"/>
        </div>
        <div class="field col-2 m-0 pr-1 pl-1">
            <label for="txtFilterSituacao">Situação</label>
            <MultiSelect id="txtFilterSituacao" v-model="filteredSituacao" :options="situacoes" class="app-multiselect"
                         dataKey="id" display="chip" optionLabel="name" placeholder="Selecione a situação..."/>
        </div>
        <div class="field col m-0 pr-1 pl-1">
            <label for="txtFilterPeriodo">Período</label>
            <div class="p-inputgroup">
                <Calendar v-model="filteredPeriodo"
                          class="w-5rem"
                          inputId="txtFilterPeriodo"
                          placeholder="Selecione o período..."
                          selectionMode="range"/>
                <Dropdown id="txtEventType"
                          v-model="selectedTipoPeriodo"
                          :options="tiposPeriodo"
                          optionLabel="name"
                          optionValue="id"/>
            </div>
        </div>
        <div class="col-fixed field m-0 pl-1" style="width: auto">
            <label class="block">&nbsp;</label>
            <Button class="p-button-outlined pt-3" icon="pi pi-filter" @click="onFilter"></Button>
        </div>
    </div>
</template>

<script>
import { getClientBase } from '@/services/http';
import { showError, showSuccess } from '@/utils/Toast';
import { EsocialEventoSituacaoEnum, EsocialEventoSituacaoEnumLabel } from '../eventos/enums/EsocialEventoSituacaoEnum';
import { EsocialEventoTipoEnum, EsocialEventoTipoEnumEnumLabel } from '../eventos/enums/EsocialEventoTipoEnum';
import MultiSelectCliente from '@/pages/clientes/components/MultiSelectCliente.vue';
import MultiSelectEsocialFuncionario
    from '@/pages/esocial/eventos/components/_shared/MultiSelectEsocialFuncionario.vue';
import MultiSelectEsocialUnidade from '@/pages/esocial/eventos/components/_shared/MultiSelectEsocialUnidade.vue';

export default {
    components: { MultiSelectEsocialUnidade, MultiSelectEsocialFuncionario, MultiSelectCliente },
    props: {
        load: {
            type: Function
        },
        selectedRecords: {
            type: Array
        },
        title: {
            type: String,
            default: ''
        },
        showTipo: {
            type: Boolean,
            default: true
        },
        isCancelable: {
            type: Boolean,
            default: false
        },
        isSendable: {
            type: Boolean,
            default: false
        },
        showCancel: {
            type: Function
        }
    },
    data() {
        return {
            filteredEventoTipo: null,
            filteredCliente: null,
            filteredFuncionario: null,
            filteredUnidade: null,
            filteredSituacao: null,
            filteredPeriodo: null,
            selectedTipoPeriodo: 'data',
            situacoes: Object.keys(EsocialEventoSituacaoEnum).map(key => {
                return {
                    id: EsocialEventoSituacaoEnum[key],
                    name: EsocialEventoSituacaoEnumLabel.get(EsocialEventoSituacaoEnum[key])
                };
            }),
            tiposEventos: Object.keys(EsocialEventoTipoEnum).map(key => {
                return {
                    id: EsocialEventoTipoEnum[key],
                    name: EsocialEventoTipoEnumEnumLabel.get(EsocialEventoTipoEnum[key])
                };
            }),
            tiposPeriodo: [
                { id: 'data', name: 'Data evento' },
                { id: 'created_at', name: 'Data geração' }
            ]
        };
    },
    mounted() {
        setTimeout(() => {
            let executeOnFilter = false;
            if (this.$route.query.evento_tipo) {
                this.filteredEventoTipo = [this.tiposEventos.find(value => value.id === this.$route.query.evento_tipo)];
                executeOnFilter = true;
            }
            if (this.$route.query.situacao) {
                this.filteredSituacao = [this.situacoes.find(value => value.id == this.$route.query.situacao)];
                executeOnFilter = true;
            }
            if (executeOnFilter) {
                this.onFilter();
            }
        }, 100);
    },
    methods: {
        async sendEventos() {
            const ids = this.selectedRecords.map(element => element.id);

            try {
                const url = `/esocial/lotes/enviar`;
                await getClientBase().post(url, ids);

                this.onFilter();

                showSuccess(this.$toast, 'Eventos enviados');
            } catch (error) {
                console.error(error);
                showError(this.$toast, error);
            }
        },
        async getEventos() {
            try {
                const url = `/esocial/lotes/atualizar`;
                await getClientBase().put(url);

                this.onFilter();

                showSuccess(this.$toast, 'Eventos atualizados');
            } catch (error) {
                console.error(error);
                showError(this.$toast, error);
            }
        },
        onFilter() {
            this.filter = [];
            if (this.filteredEventoTipo?.length) {
                this.filter.push({ eventoTipo: this.filteredEventoTipo.map(element => element.id) });
            }
            if (this.filteredCliente?.length) {
                this.filter.push({ cliente: this.filteredCliente.map(element => element.id) });
            }
            if (this.filteredFuncionario?.length) {
                this.filter.push({ funcionario: this.filteredFuncionario.map(element => element.id) });
            }
            if (this.filteredUnidade?.length) {
                this.filter.push({ unidade: this.filteredUnidade.map(element => element.id) });
            }
            if (this.filteredSituacao?.length) {
                this.filter.push({ situacao: this.filteredSituacao.map(element => element.id) });
            }
            if (this.filteredPeriodo) {
                this.filter.push({ periodo: { tipo: this.selectedTipoPeriodo, datas: this.filteredPeriodo } });
            }
            this.load(this.filter);
        }
    }
};
</script>

<style lang="scss">
.app-multiselect .p-multiselect-label-container {
    padding: 0.25rem;
}
</style>
