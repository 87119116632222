<template>
    <AppMultiselect
        ref="multiselect"
        v-model="value"
        :service="$service"
        optionLabel="name"
        placeholder="Selecione a unidade..."
        @change="change"
    />
</template>
<script>
import BaseService from '@/services/BaseService';

export default {
    name: 'MultiSelectEsocialUnidade',
    props: {
        modelValue: {
            type: Object
        },
        change: {
            type: Function
        },
        customers: {
            type: Array
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            $service: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        customers() {
            if (!this.customers) return;
            setTimeout(() => {
                this.$refs.multiselect.load({
                    itemSize: this.itemSize,
                    filtrosExtras: { customerIds: this.customers.map(element => element.id) }
                });
            }, 100);
        }
    },
    created() {
        this.$service = new BaseService('/customer/branches');
    }
};
</script>
